const copySelection = (clipboardId, sourceElement) => {
  var r = document.createRange();
  const sourceTextElement = document.querySelector(
    `[data-clipboard-id='${clipboardId}']`
  );
  if (sourceTextElement) {
    r.selectNode(sourceTextElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    sourceElement.innerHTML = ' (Copied!)';
  }
};

const copyButtons = document.querySelectorAll('[data-clipboard]');
copyButtons.forEach(copyButton => {
  copyButton.addEventListener('click', e => {
    e.preventDefault();
    copySelection(copyButton.dataset.clipboard, copyButton);
  });
});
